// src/checkoutUtils.js
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, onSnapshot } from 'firebase/firestore';

export const handleCheckout = async () => {
  const db = getFirestore();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
    if (!currentUser) {
      alert('You must be signed in to subscribe.');
      return;
    }
  
    try {
      // Add a document to the 'checkout_sessions' sub-collection
      const checkoutSessionsRef = collection(db, 'users', currentUser.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        price: 'price_1QnesGAWnK0AWkSwUfCy81xq', // Replace with your Stripe Price ID
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
  
      // Listen for changes to the checkout session document
      onSnapshot(docRef, (doc) => {
        const { error, url } = doc.data();
  
        if (error) {
          alert(`An error occurred: ${error.message}`);
          console.error('Stripe Checkout error:', error);
        }
  
        if (url) {
          // Redirect to the Stripe Checkout page
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to create checkout session. Please try again.');
    }
  };