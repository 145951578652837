import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { auth, firestore } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import FileHandler from './FileHandler'; // Import the FileHandler component
import { handleCheckout } from './checkoutUtils'; // Import handleCheckout
import './Navbar.css';
import ProfileOrLogin from './ProfileOrLogin';
import Login from './Login'; // Import the Login modal

const Navbar = () => {
  const [userId, setUserId] = useState(null); // Store the user ID
  const [loadingUpgrade, setLoadingUpgrade] = useState(false); // Loading state for Upgrade button
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const user = auth.currentUser;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);

  // Reset isExpanded when switching to desktop view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isExpanded) {
        setIsExpanded(false);
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  // Track user authentication and subscription status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        checkSubscriptionStatus(user.uid);
      } else {
        setUserId(null);
        setIsSubscribed(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to check if the user has an active subscription
  const checkSubscriptionStatus = async (userId) => {
    if (!userId) return;

    const subscriptionRef = collection(firestore, "users", userId, "subscriptions");
    const q = query(subscriptionRef, where("status", "in", ["trialing", "active"]));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setIsSubscribed(snapshot.docs.length > 0);
    });

    return () => unsubscribe();
  };

  const handleUpgradeClick = async () => {
    if (loadingUpgrade) return; // Prevent multiple clicks while loading

    // If the user is not logged in, show the login modal instead
    if (!user) {
      setLoginOpen(true);
      return;
    }

    setLoadingUpgrade(true);

    try {
      await handleCheckout(); // Redirect to checkout
    } catch (error) {
      console.error("Error initiating checkout:", error);
      setLoadingUpgrade(false); // Stop spinner on error
    }
  };
  

  return (
    <nav className="navbar">
      <div className="navbar-row top-row">
        <Link to="/" className="brand">PositionPrep</Link>

        {/* File Upload and Preview Section */}
        <div className="file-upload-preview">
          <FileHandler />
        </div>
      </div>

      <div className={`navbar-row navLinks bottom-row ${isExpanded ? "expanded" : ""}`}>
        <Link to="/interview-questions" className="nav-text-link">Mock Interview Questions</Link>

        {/* Conditionally Show Upgrade Button Only If Not Subscribed */}
        {!isSubscribed && (
          <button
            className="upgrade-button"
            onClick={handleUpgradeClick}
            disabled={loadingUpgrade}
          >
            {loadingUpgrade ? (
              <span>
                <div className="spinner"></div>
              </span>
            ) : (
              'Upgrade'
            )}
          </button>
        )}

        <div className="profile-link">
          <ProfileOrLogin user={user} />
        </div>
        {/* Login Modal */}
        <Login isOpen={isLoginOpen} onClose={() => setLoginOpen(false)} />
      </div>
    </nav>
  );
};

export default Navbar;

